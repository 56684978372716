let Config = {
    PERSIST_SECRET_KEY: "click123",
    ENVIRONMENT: "DEVELOPMENT",
    ENVIRONMENTS: {
        LOCAL: {
            API_URL: "https://dev-api-booth.digitalrange.com/",
            BASE_IMG_PATH: "https://s3.amazonaws.com/booth.digitalrange.dev"
        },
        DEVELOPMENT: {
            API_URL: "https://dev-api-booth.digitalrange.com/",
            BASE_IMG_PATH: "https://s3.amazonaws.com/booth.digitalrange.dev"
        },
        PRODUCTION: {
            API_URL: "https://dev-api-booth.digitalrange.com/",
            BASE_IMG_PATH: "https://s3.amazonaws.com/booth.digitalrange.dev"
        }
    }
};

Config.env = () => {
    return Config.ENVIRONMENTS[Config.ENVIRONMENT];
};

export default Config;